<template>
  <div v-if="message" style="position:fixed;top:0;left:30%;z-index:999;">
    <base-material-alert color="red" dark :dismissible="false" style="margin: 0;">
      {{ message }}
    </base-material-alert>
  </div>
</template>

<script>
  export default {
    name: 'AlertNotification',
    props: {
      message: {
        type: String,
        required: true
      }
    }
  };
</script>
